<template>
    <b-modal
        id="modalStatusCreateEdit"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="hideModal"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="row">
            <div class="col-12 d-flex justify-content-center flex-column mb-2">
                <b-form-checkbox
                    v-model="data.is_internal"
                    name="is_remake"
                    :disabled="editMode"
                    switch
                >
                    Internal status
                </b-form-checkbox>
            </div>
            <div v-if="!data.is_internal" class="col-12">
                <div class="d-flex justify-content-center flex-column mb-2">
                    <b-form-checkbox
                        v-model="data.is_remake"
                        name="is_remake"
                        :disabled="editMode"
                        switch
                    >
                        Remake status
                    </b-form-checkbox>
                </div>
                <div class="form-group">
                    <label for="code">Magento Code</label>
                    <input
                        id="code"
                        v-model="data.code"
                        class="form-control"
                        :class="{
                            'is-invalid': $v.data.code.$error || isCodeTaken
                        }"
                        type="text"
                        @input="isCodeTaken = false"
                    />
                    <span v-if="isCodeTaken" class="invalid-feedback">
                        Code is already in use
                    </span>
                </div>
            </div>
            <div v-else class="col-12">
                <div class="form-group">
                    <label>Code</label>
                    <b-form-select
                        v-model="data.code"
                        :options="statuses"
                    />
                </div>
            </div>

            <div class="col-12 form-group">
                <label for="name">Name</label>
                <input
                    id="name"
                    v-model="data.name"
                    class="form-control"
                    :class="{ 'is-invalid': $v.data.name.$error }"
                    type="text"
                />
            </div>
            <div class="col-12 form-group">
                <label for="text">Delivery Time Text</label>
                <input
                    id="text"
                    v-model="data.text"
                    class="form-control"
                    :class="{ 'is-invalid': $v.data.text.$error }"
                    type="text"
                />
            </div>
            <div class="col-12 my-1">
                <div class="alert alert-blue border-0">
                    <i class="fas fa-info mr-1" />
                    In 'Delivery Time Text' you can use variables. There are predefined
                    variables for <b>{data}</b> and <b>{days}</b>.<br>
                    <span class="ml-2">
                        <b>{days}</b> variable already includes "day/days" word.
                    </span>
                </div>
            </div>
            <div class="col-12 form-group">
                <label for="color">Color</label>
                <color-picker
                    :key="data.id || 'none_hex'"
                    v-model="data.hex_color"
                    :color="data.hex_color"
                    :error-border="$v.data.hex_color.$error"
                />
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    class="btn btn-success waves-effect waves-light"
                    @click="submitStatusDataHandler"
                >
                    Save
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import ColorPicker from '@components/elements/ColorPicker';

export default {
    components: {
        ColorPicker
    },

    props: {
        updatingStatus: {
            type: Object,
            required: false,
            default: null
        }
    },

    data() {
        return {
            data: {},
            isCodeTaken: false,
            statuses: [
                { text: 'PAID', value: 'PAID', id: 0 },
                { text: 'APP-PAID', value: 'APP-PAID', id: 1 },
                { text: 'IKF-PAID', value: 'IKF-PAID', id: 2 },
                { text: 'IKF-COMPLETE', value: 'IKF-COMPLETE', id: 3 }
            ],
        };
    },

    validations: {
        data: {
            code: {
                required
            },
            name: {
                required
            },
            text: {
                required
            },
            hex_color: {
                required
            }
        }
    },

    computed: {
        editMode() {
            return !!(this.updatingStatus && this.updatingStatus.id);
        },

        modalTitle() {
            return this.editMode ? 'Edit status' : 'Add new status';
        }
    },

    methods: {
        ...mapActions({
            createStatus: 'trackingStatuses/store',
            updateStatus: 'trackingStatuses/update'
        }),

        hideModal() {
            this.$bvModal.hide('modalStatusCreateEdit');
            this.$emit('hide');
            this.data = {};
            this.isCodeTaken = false;
            this.$v.$reset();
        },

        async openModalHandler() {
            await this.$nextTick();

            if (this.updatingStatus) {
                this.data = {
                    ...this.updatingStatus
                };
            }
        },

        async submitStatusDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                let message = 'Status has been updated!';

                if (this.editMode) {
                    const status = await this.updateStatus({
                        id: this.updatingStatus.id,
                        data: this.data
                    });

                    this.$emit('updated', status);
                } else {
                    const status = await this.createStatus(this.data);

                    message = 'Status has been added!';
                    this.$emit('stored', status);
                }

                this.$toaster(message);

                this.hideModal();
            } catch (error) {
                console.error(error);

                if (
                    error.response &&
                    error.response.status === 422 &&
                    error.response.data.errors &&
                    error.response.data.errors.code
                ) {
                    this.isCodeTaken = true;
                } else {
                    const message =
                        error.response &&
                        error.response.data &&
                        error.response.data.error
                            ? error.response.data.error
                            : 'Something went wrong!';

                    this.$toasterError(message);
                }
            }
        }
    }
};
</script>
