<template>
    <layout>
        <page-header :title="title" :items="items" />

        <awesome-table
            :labels="labels"
            :items="statuses"
            :options="{ sort: true }"
            :pagination="pagination"
            @add-button-click="$bvModal.show('modalStatusCreateEdit')"
            @row-click="showEditModal"
            @pagination-change="onPaginationChange"
        >
            <template #[`items.is_remake`]="{ item }">
                <span
                    v-if="item.is_remake"
                    class="badge bg-soft-success text-success"
                >
                    Yes
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    No
                </span>
            </template>
            <template #[`items.is_internal`]="{ item }">
                <span
                    v-if="item.is_internal"
                    class="badge bg-soft-success text-success"
                >
                    Yes
                </span>
                <span v-else class="badge bg-soft-danger text-danger">
                    No
                </span>
            </template>
            <template #[`items.actions`]="{ item }">
                <a
                    href="#"
                    class="action-icon"
                    @click.prevent.stop="removeStatus(item.id)"
                >
                    <i class="far fa-trash-alt" />
                </a>
            </template>
        </awesome-table>

        <create-edit-modal
            :updating-status="statusToUpdate"
            @stored="onStatusStored"
            @updated="onStatusUpdated"
            @hide="statusToUpdate = null"
        />
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import CreateEditModal from '@components/tracking-status/CreateEditModal';

export default {
    page: {
        title: 'Tracking Statuses',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        CreateEditModal
    },

    data() {
        const {
            page: currentPage = 1,
            perPage = 10,
            sortBy = 'created_at',
            order = 'DESC',
            q = null,
            filters = '{}'
        } = this.$route.query;

        return {
            title: 'Tracking Statuses',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Tacking Statuses',
                    active: true
                }
            ],
            statuses: [],
            labels: [
                'code',
                'name',
                'is_remake',
                'is_internal',
                {
                    value: 'created_at',
                    filter: 'formatDate'
                },
                { value: 'actions', sortable: false }
            ],
            pagination: {
                currentPage: parseInt(currentPage),
                perPage: parseInt(perPage),
                total: 0,
                search: q || '',
                sortBy,
                descending: order.toLowerCase() === 'desc'
            },
            filters: JSON.parse(filters),
            statusToUpdate: null
        };
    },

    mounted() {
        this.fetchStatuses();
    },

    methods: {
        ...mapActions({
            getStatuses: 'trackingStatuses/index',
            deleteStatus: 'trackingStatuses/destroy'
        }),

        async fetchStatuses() {
            try {
                const { rows, count } = await this.getStatuses(this.pagination);

                this.statuses = [...rows];
                this.pagination.total = count;
                this.pagination.pages = Math.ceil(
                    this.pagination.total / this.pagination.perPage
                );
            } catch (error) {
                console.error(error);

                this.$toasterError();
            }
        },

        showEditModal(status) {
            this.statusToUpdate = status;
            this.$bvModal.show('modalStatusCreateEdit');
        },

        onStatusStored(status) {
            this.statuses = [status, ...this.statuses];
        },

        onStatusUpdated(status) {
            const statusIndex = this.statuses.findIndex(
                item => item.id === status.id
            );

            if (statusIndex === -1) {
                return;
            }

            this.statuses[statusIndex] = status;
            this.statuses = [...this.statuses];
        },

        async removeStatus(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this status?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteStatus(id);

                this.statuses = this.statuses.filter(item => item.id !== id);

                this.$toaster('Status has been removed!');
            } catch (error) {
                console.error(error);

                const { response } = error;
                const message =
                    response && response.status === 422 && response.data
                        ? response.data
                        : 'Something went wrong';

                this.$toasterError(message);
            }
        },

        onPaginationChange(pagination) {
            this.pagination = {
                ...pagination
            };

            this.fetchStatuses();
        }
    }
};
</script>
